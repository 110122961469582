<td class="w-8 h-8" colspan="1" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="bg-gray-100 rounded-full w-8 h-8 overflow-hidden">
        @if (result() && result().item!.images[0] && result().item!.images[0].image; as image) {
            <img
                [image]="image"
                [width]="128"
                [height]="128"
                alt="item().name"
                class="w-full h-full object-cover"
            />
        }
    </div>
</td>
<td class="text-xs" colspan="2" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="font-semibold flex-row flex">
        {{ result().item!.category!.name }}
        @if(isZeroEmission()){
            <span class="ml-1">
            <svg fill="currentColor" width="24" height="24" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="text-green-600 w-3 h-3 mr-2">
                <path fill="currentColor" d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path>
            </svg>
        </span>
        }
    </div>
    <div class="text-wrap">
        {{ result().item!.company!.name }}
    </div>
</td>
<td class="text-xs" colspan="2" [ngClass]="{'bg-gray-50': lessRelevant()}">
    <div class="flex items-center justify-between">
        <div class="opacity-65 text-wrap">
            {{ address() }}
        </div>
    </div>
</td>
<td class="text-xs" colspan="1" [ngClass]="{'bg-gray-50': lessRelevant()}" [pTooltip]="tooltip" [tooltipDisabled]="!lessRelevant()">
    <div class="flex items-center justify-between">
        <div class="opacity-65 whitespace-nowrap">
            @if (result().item!.status?.isListedAndAvailable) {
                <ng-container i18n="@@component.search-supply-result-table.item-available">
                    Nu beschikbaar
                </ng-container>
            } @else {
                <ng-container i18n="@@component.search-supply-result-table.item-unavailable">
                    Niet beschikbaar
                </ng-container>
            }
        </div>
        <div>
            <ng-icon name="heroChevronRight" size="1.2em"/>
        </div>
    </div>
    <ng-template #tooltip>
        <span class="text-xs leading-0">
            Voldoet niet volledig aan de zoekcriteria
        </span>
    </ng-template>
</td>
